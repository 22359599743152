const consultationPhoneNumber = '+370 660 98000'
const defaultEmail = 'info@foros.com'
const contacts = { phoneNumber: consultationPhoneNumber, email: defaultEmail }
const authenticatedLandingPath = '/dashboard'

const list = [
  {
    name: 'foros.com',
    locale: 'en',
    countryCode: 'GB',
    consultationPhoneNumber,
    mapCoordinates: {
      lat: 54.687947,
      lng: 25.264014,
    },
    authenticatedLandingPath,
    contacts,
  },
  {
    name: 'foros.lt',
    locale: 'lt',
    countryCode: 'LT',
    consultationPhoneNumber,
    mapCoordinates: {
      lat: 54.687947,
      lng: 25.264014,
    },
    forestBookEnabled: true,
    authenticatedLandingPath,
    contacts: {
      ...contacts,
      email: 'info@foros.lt',
    },
  },
  {
    name: 'foros.lv',
    locale: 'lv',
    countryCode: 'LV',
    consultationPhoneNumber: '+371 285 50000',
    mapCoordinates: {
      lat: 56.8814245336622,
      lng: 24.606375017264053,
    },
    authenticatedLandingPath: '/auctions',
    contacts: {
      email: 'info@foros.lv',
      phoneNumber: '+371 201 99989',
    },
  },
  {
    name: 'foros.ee',
    locale: 'et',
    countryCode: 'EE',
    consultationPhoneNumber,
    mapCoordinates: {
      lat: 58.69343981835519,
      lng: 25.0197604474133,
    },
    authenticatedLandingPath,
    contacts,
  },
  {
    name: 'foros.fi',
    locale: 'fi',
    countryCode: 'FI',
    consultationPhoneNumber,
    mapCoordinates: {
      lat: 64.17773641357306,
      lng: 25.965880504635972,
    },
    authenticatedLandingPath,
    contacts,
  },
  {
    name: 'miskoaukcionas.lt',
    locale: 'lt',
    countryCode: 'LT',
    consultationPhoneNumber,
    mapCoordinates: {
      lat: 54.687947,
      lng: 25.264014,
    },
    forestBookEnabled: true,
    authenticatedLandingPath,
    contacts: {
      ...contacts,
      email: 'info@foros.lt',
    },
  },
]

const current = list.find(({ name }) => name === process.env.NEXT_PUBLIC_DOMAIN)
if (!current) {
  console.warn('Current domain not found, set correct NEXT_PUBLIC_DOMAIN environment variable')
}

module.exports = {
  list,
  current,
}
