import React, { FC, useEffect } from 'react'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { appWithTranslation } from 'next-i18next'
import { ApolloProvider } from '@apollo/client'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { Settings } from 'luxon'
import TagManager from 'react-gtm-module'
import { useApollo } from '../utils/apolloClient'
import { domains } from '@foros-fe/core/config'
import { Cookies } from '@foros-fe/core'

import 'core-js/stable/global-this'
import 'core-js/stable/object/from-entries'

import 'tailwindcss/tailwind.css'

const { name, locale } = domains.current

Settings.defaultLocale = locale

const SafeHydrate: FC<PageProps & { ssr?: boolean }> = ({ children, ssr, isServer }) => {
  return <div suppressHydrationWarning>{!ssr && isServer ? null : children}</div>
}

function App({ Component, pageProps: { ssr, ...pageProps }, err }: AppProps & { err: Error }) {
  const apolloClient = useApollo(pageProps)
  const isServer = typeof window === 'undefined'

  useEffect(() => {
    const gtmId = process.env.NEXT_PUBLIC_GTM_ID

    if (gtmId) {
      TagManager.initialize({ gtmId })
    }
  }, [])

  return (
    <>
      <Head>
        <title>FOROS | {name}</title>
        <meta name='description' content='FOROS Global' />
        <meta name='viewport' content='initial-scale=1, width=device-width' />
      </Head>
      <SafeHydrate isServer={isServer} ssr={ssr}>
        <GoogleReCaptchaProvider reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_KEY}>
          <ApolloProvider client={apolloClient}>
            <Component {...pageProps} isServer={isServer} err={err} />
            <Cookies />
          </ApolloProvider>
        </GoogleReCaptchaProvider>
      </SafeHydrate>
    </>
  )
}

export default appWithTranslation(App)
